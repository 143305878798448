var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-selector",attrs:{"id":"product-selector"}},[(_vm.hasLegacyData)?_c('div',{staticClass:"ab-mb-5"},[_c('div',[_vm._v(" Please provide the specific part number and quantity for the product family you previously selected: ")]),_c('ul',_vm._l((_vm.legacyData),function(data,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(data.name)+": Quantity "+_vm._s(data.quantity)+" ")])}),0),_c('div',[_vm._v(" Previous Amount: "+_vm._s(_vm.legacyDataAmount)+" ")])]):_vm._e(),(!_vm.loadingProducts)?_c('div',{staticClass:"product-selector--select"},[_c('div',{},[_c('h4',[_vm._v(" IMPORTANT: In order to receive Specialty Program discounts (ex. Education or Hospitality), please make designation below. Select \"None\" for base deal registration discounts. ")]),_c('div',{staticClass:"ab-flex ab-mb-5"},[_c('BaseDropdown',{key:_vm.componentKey,attrs:{"label":"If opportunity applies to a speciality program please make designation below:","items":_vm.getSpecialityDiscounts,"disabled":!!_vm.tableData.length && !_vm.isUserAdmin,"error":_vm.$v.selectedSpecialityProgram.$anyDirty && _vm.$v.selectedSpecialityProgram.$invalid,"required":""},on:{"select":_vm.handleSelectSpecialityProgram},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}}],null,false,4107304321),model:{value:(_vm.selectedSpecialityProgram),callback:function ($$v) {_vm.selectedSpecialityProgram=$$v},expression:"selectedSpecialityProgram"}})],1)]),(_vm.showNotERate)?_c('div',{staticClass:"product-selector--dropdowns"},[_c('div',{staticClass:"ab-w-90"},[(_vm.showNotQualifiedMessage)?_c('div',{staticClass:"not-qualify-message"},[_vm._v(" Your company is not qualified for this Specialty Program. Either remove the Specialty Program or contact your Cambium salesperson or "),_c('a',{attrs:{"href":"mailto:partner@cambiumnetworks.com"}},[_vm._v("partner@cambiumnetworks.com")]),_vm._v(" before proceeding ")]):_vm._e(),_c('div',{staticClass:"ab-flex ab-mb-5"},[_c('base-dropdown',{attrs:{"label":"Product Category","items":_vm.getCategories,"required":"","error":_vm.$v.productForm.product.$anyDirty && _vm.$v.productForm.product.$invalid},on:{"select":_vm.handleSelectCategory},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}}],null,false,4107304321),model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}),_c('base-dropdown',{staticClass:"ab-ml-5",attrs:{"label":"Product SKU","items":_vm.getSKUs,"required":"","error":_vm.$v.productForm.product.$anyDirty && _vm.$v.productForm.product.$invalid},on:{"select":_vm.handleSelectSKU},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"dropdown-item"},[_c('div',[_vm._v(" "+_vm._s(item.title)+" ")]),(item.description)?_c('div',[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e()])]}}],null,false,2659797118),model:{value:(_vm.selectedSKU),callback:function ($$v) {_vm.selectedSKU=$$v},expression:"selectedSKU"}})],1),_c('div',{staticClass:"ab-flex"},[(_vm.shouldShowDiscount)?_c('BaseInput',{staticClass:"cpq_discount ab-mr-5",attrs:{"label":"Discount","icon":"fas fa-percentage","input-type":"number","disabled":""},model:{value:(_vm.productForm.discount),callback:function ($$v) {_vm.$set(_vm.productForm, "discount", $$v)},expression:"productForm.discount"}}):_vm._e(),(_vm.shouldShowDiscount)?_c('BaseInput',{staticClass:"ab-w-30 ab-mr-5 cpq_msrp",attrs:{"label":"MSRP","disabled":"","icon":"fas fa-dollar-sign","input-type":"number"},model:{value:(_vm.productForm.msrp),callback:function ($$v) {_vm.$set(_vm.productForm, "msrp", $$v)},expression:"productForm.msrp"}}):_vm._e(),_c('BaseInput',{staticClass:"cpq_quantity",attrs:{"label":"Quantity","required":"","input-type":"number","min-value":"1","error":_vm.$v.productForm.quantity.$anyDirty && _vm.$v.productForm.quantity.$invalid},model:{value:(_vm.productForm.quantity),callback:function ($$v) {_vm.$set(_vm.productForm, "quantity", $$v)},expression:"productForm.quantity"}})],1)]),_c('div',{staticClass:"product-selector--dropdowns--icon ab-bg-green",on:{"click":_vm.handleAddProduct}},[_c('i',{staticClass:"fas fa-plus"})])]):_vm._e(),(_vm.showNotERate)?_c('div',{staticStyle:{"overflow-x":"auto","margin-bottom":"25px"}},[_c('BaseTable',{staticClass:"ab-mt-10 ab-w-full",attrs:{"headers":_vm.getTableHeaders,"data":_vm.tableData},scopedSlots:_vm._u([{key:"discount",fn:function(ref){
var item = ref.item;
return [(item.discount)?_c('div',[_vm._v(" "+_vm._s(item.discount)+"% ")]):_c('div',[_vm._v(" - ")])]}},{key:"msrp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("price")(item.msrp))+" ")]}},{key:"quantity",fn:function(ref){
var item = ref.item;
return [_c('BaseInput',{staticClass:"cpq_item_quantity",attrs:{"input-type":"number","min-value":"1","error":_vm.$v.productForm.quantity.$anyDirty && _vm.$v.productForm.quantity.$invalid},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("price")(_vm.getProductTotal(item)))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"product-selector--dropdowns--icon ab-bg-red",on:{"click":function($event){return _vm.handleRemoveProduct(item)}}},[_c('i',{staticClass:"fas fa-minus"})])]}}],null,false,968650458)})],1):_vm._e(),(_vm.showERate)?_c('erate-app',{key:_vm.keyRate,attrs:{"owner-id":_vm.getOwnerId,"uploaded-file":_vm.getUploadedFile,"excel":_vm.getExcelData,"products":_vm.getCategories,"configurator":_vm.getConfigurator,"title":_vm.getERateTitle,"status":_vm.getStatus,"distributors-field":_vm.getFormFields.erate_distributor,"uat-field":_vm.getFormFields.erate_discount_uat},on:{"uploadSuccess":_vm.handleUploadSuccess,"excelProcessed":_vm.handleExcelProcessed,"configuratorSelected":_vm.handleConfiguratorSelected,"selectorEvent":_vm.handleSelectorEvent,"enableSkip":_vm.handleSkipEvent,"resetBom":_vm.handleResetBOM}}):_vm._e()],1):_vm._e(),((_vm.skipConfigurator === 'Yes' && ['submitted', 'pre-approved'].includes(_vm.getStatus)))?_c('div',{staticStyle:{"margin-bottom":"40px"}},[_c('base-button',{staticClass:"primary--button ab-border-gray ab-text-primary bg-white",staticStyle:{"margin-top":"20px","cursor":"pointer"},on:{"click":_vm.resetSkipConfigurator}},[_vm._v(" Upload an E-Rate 2022 BOM Configurator ")])],1):_vm._e(),(!_vm.shouldShowDiscount)?_c('div',{staticClass:"not-qualify-message ab-mt-5"},[_vm._v(" Please contact your distributor for pricing. ")]):_vm._e(),(_vm.shouldShowERateRequiredMessage)?_c('div',{staticClass:"not-qualify-message ab-mt-5"},[_vm._v(" For E-rate deal registrations, please refer to the "),_c('a',{attrs:{"href":"https://channel.cambiumnetworks.com/engage/cambium-e-rate-2021-partner-resource-kit/?play=85854","target":"_blank"}},[_vm._v(" E-rate Resource Kit ")]),_vm._v(" for specific instructions ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'
import ProductSelector from './App.vue'
import '@/assets/styles.scss'
import VueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element'
import VueFileAgent from 'vue-file-agent'
import Toast from 'vue-toastification'

Vue.config.productionTip = false

const options = {
}

Vue.use(Toast, options)
Vue.use(VueCustomElement)
Vue.use(VueFileAgent)

Vue.customElement('product-selector', ProductSelector, {})

<template>
  <!-- Base Dropdown -->
  <div class="base-dropdown">
    <!-- Dropdown Label -->
    <label class="base-dropdown--label">
      {{ label }}

      <!-- Required Star -->
      <span v-if="required">*</span>
    </label>

    <!-- Dropdown Input -->
    <div
      class="base-dropdown--wrapper"
      :class="{ getInputWrapperClasses, 'ab-border-blue': showDropdown, 'ab-border-grey': !showDropdown }"
      @click="showDropdown = true"
    >
      <input
        v-model="search"
        :class="getInputWrapperClasses"
        placeholder="Select"
        :disabled="disabled"
      >

      <i class="fas fa-caret-down" />
    </div>

    <!-- Error -->
    <div
      v-if="error"
      class="base-dropdown--error-message"
    >
      Field is required
    </div>

    <!-- Dropdown Dropdown -->
    <div
      v-if="showDropdown && !disabled"
      v-on-dismiss="{ watch: showDropdown, callback: closeDropdown }"
      class="base-dropdown--dropdown"
    >
      <!-- Not Found Message -->
      <div v-if="!getFilteredItems.length">
        <slot
          v-if="$slots['no-data']"
          name="no-data"
        />

        <div
          v-else
          class="base-dropdown--dropdown--item"
        >
          No data found
        </div>
      </div>

      <!-- Items List -->
      <div v-else>
        <div
          v-for="(item, index) in getFilteredItems"
          :key="index"
          class="base-dropdown--dropdown--item"
          @click="selectItem(item)"
        >
          <!-- Item Slot -->
          <slot
            v-if="$scopedSlots['item']"
            name="item"
            :item="item"
          />

          <!-- Item Default -->
          <div v-else>
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueDismiss from '../../mixins/vueDismiss'

export default {
  name: 'BaseDropdown',
  mixins: [vueDismiss],
  props: {
    // Model Value
    value: {
      type: [Object, String],
      required: false,
      default: ''
    },
    // Dropdown Label
    label: {
      type: String,
      required: false,
      default: ''
    },
    // Is Field Required
    required: {
      type: Boolean,
      required: false
    },
    // Dropdown Items
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    error: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      showDropdown: false,
      search: '',
      hasSearched: false
    }
  },
  computed: {
    // Get Filtered Items
    getFilteredItems () {
      const value = typeof this.value === 'object' ? this.value.title : this.value

      if (this.items.find(i => i.title === value) && !this.hasSearched) return this.items.filter(i => i.title !== value)

      return this.items.filter(item => item.title.toLowerCase().includes(this.search.toLowerCase()))
    },
    // Get Input Wrapper Classes
    getInputWrapperClasses () {
      const classes = []

      // Add error class
      this.error ? classes.push('ab-border-red') : classes.push('ab-border-grey')

      if (this.disabled) {
        classes.push('base-dropdown--wrapper--disabled')
      } else {
        classes.push('base-dropdown--wrapper--enabled')
      }

      return classes
    }
  },
  watch: {
    value (newValue) {
      if (typeof newValue === 'object' && !newValue.title) {
        this.search = ''
      }
    }
  },
  mounted () {
    const value = typeof this.value === 'object' ? this.value.title : this.value

    this.search = value || ''

    this.$watch('search', (newValue, oldValue) => {
      if (oldValue && newValue && newValue !== oldValue && !this.hasSearched && value !== newValue) {
        this.hasSearched = true
      }
    })
  },
  methods: {
    // Select Item
    selectItem (item) {
      this.search = item.title
      this.$emit('select', item)
      this.closeDropdown()

      setTimeout(() => {
        this.hasSearched = false
      }, 0)
    },
    // Close Dropdown
    closeDropdown () {
      this.showDropdown = false
    }
  }
}
</script>

<style scoped lang="sass">
.base-dropdown
  min-width: 300px
  position: relative
  width: 100%

  &--label
    font-size: 15px

    span
      color: red

  &--wrapper
    align-items: center
    cursor: pointer
    border-radius: 3px
    display: flex
    margin-top: 5px
    width: 100%

    &--disabled
      background-color: #eeeeee

    &--enabled
      background-color: transparent

    input
      border: none
      cursor: pointer
      height: 100%
      overflow: hidden
      outline: none
      padding: 13px 0
      padding-left: 15px !important
      text-overflow: ellipsis
      white-space: nowrap
      width: 100%

    i
      color: #696969
      position: absolute
      margin-right: 10px
      right: 0

  &--dropdown
    animation: open 200ms ease-in
    background: white
    border: 1px solid grey
    border-radius: 5px
    max-height: 300px
    min-height: 20px
    overflow-y: auto
    position: absolute
    width: 100%
    z-index: 500

    &--item
      cursor: pointer
      padding: 15px
      font-size: 15px

      &:first-child
        padding-top: 15px

      &:last-child
        padding-bottom: 15px

      &:hover
        background: #F8FAFF

  &--error-message
    color: red
    font-size: 12px
    margin-top: 5px
    position: absolute

@keyframes open
  0%
    transform: translateY(-10px)
    opacity: 0

  100%
    transform: translateY(0)
    opacity: 1
</style>

<template>
  <!-- Base Table -->
  <table class="base-table">
    <!-- Mobile Header -->
    <thead
      v-if="$scopedSlots.mobile && shouldShowMobile"
      class="base-table--head--mobile"
    >
      <tr>
        <!-- Table Headers -->
        <th
          class="base-table--header"
        >
          Table
        </th>
      </tr>
    </thead>

    <!-- Table Head -->
    <thead
      v-else
      class="base-table--head"
    >
      <tr>
        <!-- Table Headers -->
        <th
          v-for="header in headers"
          :key="header.id"
          :class="[header.class]"
          :style="header.style"
          class="base-table--header"
        >
          {{ header.title }}
        </th>
      </tr>
    </thead>

    <!-- Mobile Body -->
    <tbody
      v-if="shouldShowMobile && $scopedSlots['mobile']"
    >
      <tr
        v-for="(row, index) in data"
        :key="index"
        class="base-table--row"
      >
        <td
          :colspan="1"
          class="p-3"
        >
          <slot
            name="mobile"
            :item="row"
          />
        </td>
      </tr>
    </tbody>

    <!-- Table Body -->
    <tbody
      v-else
      class="base-table--body"
    >
      <tr>
        <td
          v-if="!data.length"
          :colspan="headers.length"
          class="base-table--no-data"
        >
          <slot
            v-if="$scopedSlots['no-data']"
            name="no-data"
          />

          <div v-else>
            No data found
          </div>
        </td>
      </tr>

      <!-- Table Rows -->
      <tr
        v-for="(row, index) in data"
        :key="index"
        class="base-table--row"
      >
        <!-- Table Cells -->
        <td
          v-for="header in headers"
          :key="header.id"
          class="base-table--cell"
        >
          <!-- Slot For Cell -->
          <slot
            v-if="$scopedSlots[header.value]"
            :name="header.value"
            :item="row"
          />

          <!-- Default Value -->
          <div v-else>
            {{ row[header.value] }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'BaseTable',
  props: {
    // Table Headers
    headers: {
      type: Array,
      required: false,
      default: () => []
    },
    // Table Data
    data: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      windowWidth: 0
    }
  },
  computed: {
    shouldShowMobile () {
      return this.windowWidth < 900
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    // Handle Resize
    handleResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style scoped lang="sass">
table
  border-collapse: collapse
  width: 100%

.base-table
  border: 1px solid #c8c8c8
  overflow-x: auto
  max-width: 100vw

  &--header
    background-color: #f1f1f1
    color: #636b6f
    font-size: 15px
    letter-spacing: 0.5px
    padding: 15px 15px
    position: sticky
    text-align: left
    text-transform: uppercase
    top: 0

  &--row:not(:last-child)
    border-bottom: 1px solid grey

  &--cell
    background: white
    font-size: 13px
    padding: 10px 15px

  &--no-data
    font-size: 18px
    padding: 50px 0
    text-align: center
</style>

<template>
  <!-- Base Modal -->
  <div class="base-modal">
    <div
      class="base-modal--card"
      :class="[{'padding0': noPadding}]"
    >
      <div class="base-modal--toolbar">
        <slot name="toolbar" />

        <i
          class="fas fa-times base-modal--card--close"
          @click="$emit('close')"
        />
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    noPadding: {
      Type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="sass">
.base-modal
  align-items: center
  background-color: rgba(0, 0, 0, 0.29)
  display: flex
  height: 100vh
  justify-content: center
  left: 0
  position: absolute
  top: 0
  width: 100%
  z-index: 50000 !important

  &--toolbar
    font-size: 20px
    font-weight: 500

  &--card
    background-color: white
    border-radius: 4px
    display: flex
    flex-direction: column
    padding: 24px
    max-width: 100vw
    width: 600px
    position: relative

    &--close
      color: #A4B0BE
      cursor: pointer
      font-size: 20px
      position: absolute
      margin: 24px
      right: 0
      top: 0
.padding0
  padding: 0
</style>

<template>
  <div style="position:relative">
    <div class="erate">
      <h3>
        {{ title }}
      </h3>
      <ol
        v-if="title !== 'E-RATE'"
      >
        <li class="ab-mb-5">
          <h4>Select the appropriate E-Rate BOM Configurator * </h4>
          <base-dropdown
            :key="selectedConfigurator.link"
            v-model="selectedConfigurator"
            :items="configuratorList"
            :error="$v.selectedConfigurator.$anyDirty && $v.selectedConfigurator.$invalid"
            required
            class="backgroundInput"
            @select="handleSelectedConfigurator"
          >
            <template #item="{ item }">
              {{ item.title }}
            </template>
          </base-dropdown>
        </li>
        <li class="ab-mb-5">
          <h4>Download and Complete the E-Rate BOM Configurator </h4>
          <a
            v-if="selectedConfigurator && selectedConfigurator.title"
            :href="selectedConfigurator.link"
            target="_blank"
          >
            {{ selectedConfigurator.title }}
          </a>
        </li>
        <li class="ab-mb-5">
          <h4>Import your E-Rate BOM Configurator *</h4>
          <base-button
            v-if="(!uploadedFile && !localUploadedFile)"
            class="primary--button ab-border-gray ab-text-primary bg-white"
            @click="showUploadModal=true"
          >
            <i
              class="fa fa-upload ab-text-gray"
              style="margin-right: 5px;"
            />
            Import
          </base-button>
          <p
            v-if="(localUploadedFile || uploadedFile)"
            class="ab-text-gray ab-flex"
          >
            <i
              class="fa fa-file"
              style="margin-right: 5px;"
            /> {{ localUploadedFile || uploadedFile }}
            <base-button
              style="margin-left: 20px"
              class="primary--button--text kl-text-danger"
              @click="showUploadModal=true"
            >
              <i
                class="fa fa-times ab-text-gray"
                style="margin-right: 5px;"
              />
              Replace File
            </base-button>
          </p>
        </li>
        <li class="ab-mb-5">
          <h4>Review the imported data for accuracy.</h4>
        </li>
      </ol>
      <a
        style="font-size:14px"
        v-if="(!uploadedFile && !localUploadedFile)"
        @click.prevent="$emit('enableSkip', 'Yes')"
        href="#"
      >
        Skip E-Rate 2022 BOM Configurator
      </a>
      <div
        v-if="processedData.length"
      >
        <h4>Imported Data</h4>
        <base-loading-spinner v-if="loadingData" />
        <div
          v-if="!loadingData"
          style="overflow-x: auto"
        >
          <table>
            <thead>
              <th>PART NUMBER</th>
              <th>DESCRIPTION</th>
              <th>MSRP</th>
              <th>DISCOUNT</th>
              <th>PARTNER PRICE</th>
              <th>QTY</th>
              <th style="white-space: nowrap">DEAL REG<br>ELIGIBLE</th>
              <th style="white-space: nowrap">EXTENDED<br>PARTNER PRICE</th>
            </thead>
            <tr
              v-for="(row, index) in processedData"
              :key="index"
            >
              <td
                :class="[{'noteligible': !dealReg[index]}]"
                style="white-space: nowrap"
              >
                {{ row[0] || '' }}
              </td>
              <td
                :class="[{'noteligible': !dealReg[index] }]"
                style="min-width: 220px"
              >
                {{ row[1] || '' }}
              </td>
              <td :class="[{'noteligible': !dealReg[index] }]">
                {{ !dealReg[index] ? usdFormat(row[2]) : usdFormat(dealReg[index].msrp) }}
              </td>
              <td :class="[{'noteligible': !dealReg[index] }]">
                {{ !dealReg[index] ? `${roundX((row[3] || 0) * 100)}%` : `${roundX(dealReg[index].discount)}%` }}
              </td>
              <td :class="[{'noteligible': !dealReg[index] }]">
                {{ !dealReg[index] ? usdFormat(row[4]) : usdFormat(dealReg[index].partnerPrice) }}
              </td>
              <td :class="[{'noteligible': !dealReg[index] }]">
                {{ row[5] || '' }}
              </td>
              <td :class="[{'noteligible': !dealReg[index] }]">
                {{ !dealReg[index] ? 'N' : 'Y' }}
              </td>
              <td :class="[{'noteligible': !dealReg[index] }]">
                {{ !dealReg[index] ? usdFormat(row[6]) : usdFormat(dealReg[index].extendedPartnerPrice) }}
              </td>
            </tr>
            <tr>
              <td
                style="white-space: nowrap"
              >
                <strong>ERATE BOM TOTAL</strong>
              </td>
              <td colspan="6" />
              <td>
                <strong>
                  {{ totalEbom() }}
                </strong>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="(title === 'E-RATE' && ['submitted', 'pre-approved'].includes(status))">
        <base-button
          style="margin-top: 20px"
          class="primary--button ab-border-gray ab-text-primary bg-white"
          @click="resetBom"
        >
          Upload a Revised E-Rate 2022 BOM Configurator
        </base-button>
      </div>
      <div
        v-if="(localUploadedFile || uploadedFile) && !processedData.length"
        class="kl-text-danger"
      >
        <ul><li>Uploaded file does not have valid data.</li></ul>
      </div>
    </div>
    <upload-modal
      v-if="showUploadModal"
      @close="showUploadModal = false"
      @import="handleImportFile"
    />
  </div>
</template>

<script>
import Axios from 'axios'

import BaseButton from '@/components/globals/BaseButton'
import BaseDropdown from '@/components/globals/BaseDropdown'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import UploadModal from '@/components/UploadModal'

import { process as processData, distributors } from '@/services/app-services.js'
import generateRandomID from '@/utils/generateRandomID'
import BaseLoadingSpinner from '@/components/globals/BaseLoadingSpinner'

export default {
  name: 'ErateApp',
  components: {
    BaseLoadingSpinner,
    BaseButton,
    BaseDropdown,
    UploadModal
  },
  mixins: [validationMixin],
  validations: {
    selectedConfigurator: {
      required
    }
  },
  props: {
    ownerId: {
      type: String
    },
    title: {
      type: String,
      default: 'STEPS TO SET UP YOUR E-RATE 2022 DEAL REGISTRATION'
    },
    distributorsField: {
      type: String,
      default: null
    },
    uatField: {
      type: String,
      default: null
    },
    uploadedFile: {
      type: String,
      default: null
    },
    excel: {
      type: Array,
      default () {
        return []
      }
    },
    configurator: {
      type: Object,
      default () {
        return {}
      }
    },
    status: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      configuratorList: [
        {
          title: 'E-Rate BOM Configurator (XMS-Cloud)',
          link: 'https://channel.cambiumnetworks.com/engage/e-rate-bom-configurator-xms-cloud/'
        },
        {
          title: 'E-Rate BOM Configurator (XMS-Enterprise)',
          link: 'https://channel.cambiumnetworks.com/engage/e-rate-bom-configurator-xms-enterprise/'
        },
        {
          title: 'E-Rate BOM Configurator (cnMaestro & Standalone Support)',
          link: 'https://channel.cambiumnetworks.com/engage/e-rate-bom-configurator-cnmaestro/'
        }
      ],
      selectedConfigurator: {},
      showUploadModal: false,
      localUploadedFile: null,
      localExcel: [],
      eligibleProducts: [],
      priceProcessed: [],
      tableData: [],
      htmlOutput: [],
      totalMsrp: 0,
      dealReg: [],
      totalEboms: [],
      distributors: [],
      previousDistributors: '',
      loadingData: false
    }
  },
  computed: {
    processedData () {
      return this.localExcel.length ? this.localExcel : []
    }
  },
  watch: {
    async processedData (newVal, oldVal) {
      if (newVal.length) {
        this.loadingData = true
        this.dealReg = []
        this.tableData = []
        this.totalMsrp = 0
        this.totalEboms = []
        const uatField = document.querySelector(this.uatField)
        if (uatField) {
          uatField.innerHTML = ''
        }
        const deals = new Promise((resolve, reject) => {
          newVal.forEach(async (row, index, array) => {
            const dReg = await this.dealRegEligible(row, index)
            console.log(dReg)
            // console.log(index)
            // console.log(array.length - 1)
            if (index === array.length - 1) {
              resolve()
            }
          })
        })

        deals.then(() => {
          window.setTimeout(() => {
            this.loadingData = false
            this.$emit('selectorEvent', { total: this.totalMsrp, tableData: this.tableData, htmlOutput: this.htmlOutput })
          }, 3000)
        })
      }
    }
  },
  created () {
  },
  destroyed () {
    document.querySelector(this.distributorsField).innerHTML = this.previousDistributors
  },
  mounted () {
    this.fetchDistributors()
    if (this.excel.length) {
      this.localExcel = this.excel
    }
    if (this.configurator) {
      this.selectedConfigurator = this.configurator
    }
  },
  methods: {
    async fetchDistributors () {
      this.distributors = await distributors()
      let options = '<option value="-- Select --" style="display: none;">-- Select --</option>'
      const distField = document.querySelector(this.distributorsField)
      this.previousDistributors = distField.innerHTML
      const currentValue = distField.value || null
      this.distributors.forEach(distributor => {
        options += `<option value="${distributor.id}" ${parseInt(distributor.id) === parseInt(currentValue) ? 'selected="selected"' : ''}>${distributor.name}</option>`
      })
      distField.innerHTML = options
    },
    handleSelectedConfigurator (configurator) {
      this.selectedConfigurator = configurator
      this.$emit('configuratorSelected', configurator)
    },
    async handleImportFile (file) {
      if (!file.uploadedFile) {
        return
      }
      const uatField = document.querySelector(this.uatField)
      if (uatField) {
        uatField.innerHTML = ''
      }
      this.dealReg = []
      this.localUploadedFile = file.userFile
      this.$emit('uploadSuccess', file.userFile)
      this.showUploadModal = false
      const excel = await processData(file.uploadedFile)
      this.$emit('excelProcessed', excel)
      this.localExcel = excel
      // this.processFile(file)
    },
    usdFormat (value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })

      if (!value) {
        return ''
      }

      return formatter.format(value)
    },
    resetBom () {
      this.$forceUpdate()
      this.$emit('resetBom')
    },
    totalEbom () {
      let total = 0
      this.totalEboms.forEach((value) => {
        total = total + parseFloat(value)
      })
      return this.usdFormat(total)
    },
    roundX (value) {
      return value.toFixed(2)
    },
    async getDiscount (product) {
      const baseURL = process.env.VUE_APP_BASE_API_URL
      let url = `${baseURL}/?get_discounts=1&user_id=${this.ownerId}&sku=${product}`

      url += '&speciality_program=E-Rate'

      this.loadingDiscount = true
      try {
        const discounts = await Axios.get(url)

        return discounts.data || {}
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingDiscount = false
      }
    },
    async dealRegEligible (row, index) {
      if (row && row[0]) {
        this.totalEboms[index] = (parseFloat(row[6]))
        this.dealReg[index] = null

        const sku = row[0]
        const getDiscount = await this.getDiscount(sku)
        let found = false
        const index1 = index
        const fproduct = this.products.some((category) => {
          found = category.products.find(product => {
            return product.sku === sku
          })
          console.log(found)
          if (found && found.sku) {
            this.eligibleProducts.push(sku)
            const discount = ((getDiscount.discount || 0) * (found.basePrice || 0)) / 100
            const newProduct = {
              uuid: generateRandomID(),
              product: sku,
              category: category.title || '',
              sku: sku,
              discount: getDiscount.discount || 0,
              msrp: found.basePrice || 0,
              quantity: row[5] ? parseInt(row[5]) : 0,
              description: row[1] || 0,
              distributorRebate: getDiscount.distributor_rebate,
              discountDetails: getDiscount.discount_details,
              productCategory: category.title || '',
              partnerPrice: found.basePrice - discount,
              extendedPartnerPrice: (found.basePrice - discount) * (parseInt(row[5]) || 0)
            }
            this.totalEboms[index1] = (found.basePrice - discount) * (parseInt(row[5]) || 0)

            // Add product to table
            this.totalMsrp += parseFloat(newProduct.extendedPartnerPrice)
            this.tableData.push(newProduct)
            this.htmlOutput.push(`
          <p>
            ${sku} -
            ${this.usdFormat(newProduct.msrp)} x ${newProduct.quantity} =
            ${this.usdFormat(newProduct.extendedPartnerPrice)}
          </p>
        `)
            const discountUATField = document.querySelector(this.uatField)

            if (discountUATField) {
              discountUATField.innerHTML = discountUATField.innerHTML + '&#13;&#10;' + sku + ': ' + JSON.stringify(getDiscount)
            }
            this.dealReg[index1] = newProduct
            return true
          }
        })
        return fproduct
      }
      return false
    }
  }
}
</script>

<style lang="sass">
.erate
  font-family: Roboto, sans-serif
  margin: 44px auto
  padding: 36px 40px
  width: auto
  max-width: 100%
  background: #F1F5FF
  color: #1E272E

  h3
    font-style: normal
    font-weight: bold!important
    font-size: 14px
    line-height: 29px

  h4
    font-weight: 700
    font-size: 14px
    line-height: 29px
    text-transform: none!important

  ol
    padding-left: 15px!important
    margin-left: 0!important
    ::marker
      font-size: 14px
      font-weight: bold

  .backgroundInput
    .base-dropdown--wrapper
      background: #FFFFFF

    .base-dropdown--label
      display: none

  .required-red
    color: #DC004E

  table
    border-spacing: 0
    thead
      th
        background: #E8EFFE
        color: #57606F
        font-size: 12px
        line-height: 14px
        padding: 10px
        text-align: left!important
    tr
      td
        border-bottom: 1px solid #DFE4EA
        background: white
        color: #1E272E
        font-size: 12px
        line-height: 14px
        padding: 10px
        &.noteligible
          background: #F9F9FB!important

</style>

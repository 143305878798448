<template>
  <!-- Base Button -->
  <button
    :type="type"
    class="base-button"
    :class="getBaseButtonClasses"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot v-if="!loading" />

    <base-loading-spinner v-else />
  </button>
</template>

<script>
import BaseLoadingSpinner from '@/components/globals/BaseLoadingSpinner'
export default {
  name: 'BaseButton',
  components: { BaseLoadingSpinner },
  props: {
    // Primary Style
    primary: {
      type: Boolean,
      required: false
    },
    // Is Button Disabled
    disabled: {
      type: Boolean,
      required: false
    },
    // Loading State
    loading: {
      type: Boolean,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    // Text
    text: {
      type: Boolean,
      required: false
    },
    // Pill
    pill: {
      type: Boolean,
      required: false
    },
    // Outlined
    outlined: {
      type: Boolean,
      required: false
    },
    // Outlined small
    outlinedSm: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    // Get Button Classes
    getBaseButtonClasses () {
      const classes = []

      if (this.primary) classes.push('base-button--primary')
      if (this.pill) classes.push('base-button--pill')
      if (this.outlined) classes.push('base-button--outlined')
      if (this.outlinedSm) classes.push('base-button--outlined-sm')
      if (this.disabled) classes.push('base-button--disabled')
      if (this.text) classes.push('base-button--text')

      return classes
    }
  }
}
</script>

<style scoped lang="sass">
.base-button
  align-items: center
  display: flex
  border: none
  cursor: pointer
  justify-content: center
  transition: all 0.1s ease-in
  white-space: nowrap
  outline: none

  &--text
    background: none !important
    border: none !important
    padding: 0

  &--disabled
    cursor: text
    background-color: #EAEAEA !important
    color: #888888 !important

  &:hover
    filter: brightness(1.1)
</style>

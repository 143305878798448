<template>
  <!-- Base Input -->
  <div class="base-input">
    <!-- Input Label -->
    <label class="base-input--label">
      {{ label }}

      <!-- Required Star -->
      <span v-if="required">*</span>
    </label>

    <!-- Input -->
    <div
      class="base-input--wrapper"
      :class="getInputWrapperClasses"
    >
      <!-- Icon -->
      <i
        v-if="icon"
        :class="icon"
      />

      <input
        v-model="getValue"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="inputType"
        :min="minValue"
      >
    </div>

    <!-- Error -->
    <div
      v-if="error"
      class="base-input--error-message"
    >
      Field is required
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    // Input Value
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    // Input Label
    label: {
      type: String,
      required: false,
      default: ''
    },
    // Input Placeholder
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    // Value Type
    inputType: {
      type: String,
      required: false,
      default: 'text'
    },
    // Is Value Required
    required: {
      type: Boolean,
      required: false
    },
    // Input Icon
    icon: {
      type: String,
      required: false,
      default: ''
    },
    // Input has error
    error: {
      type: Boolean,
      required: false
    },
    // Is input disabled
    disabled: {
      type: Boolean,
      required: false
    },
    minValue: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    // Value Model
    getValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    // Get Input Wrapper Classes
    getInputWrapperClasses () {
      const classes = []

      // Add disabled Class
      if (this.disabled) classes.push('base-input--disabled')

      // Add error class
      this.error ? classes.push('ab-border-red') : classes.push('ab-border-grey')

      return classes
    }
  }
}
</script>

<style scoped lang="sass">
.base-input
  width: 100%

  &--wrapper
    align-items: center
    border-radius: 5px
    display: flex
    height: 40px
    position: relative
    margin-top: 5px

    input
      background: transparent
      border: none
      cursor: pointer
      font-size: 15px
      height: 100%
      line-height: 40px
      overflow: hidden
      outline: none
      padding-left: 10px !important
      text-overflow: ellipsis
      white-space: nowrap
      width: 80%

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

      &[type=number]
        -moz-appearance: textfield

    i
      color: #636b6f
      font-size: 15px
      padding-left: 10px
      transform: translateY(1px)

  &--label
    font-size: 13px

    span
      color: red

  &--disabled
    background-color: #eeeeee

  &--error-message
    color: red
    font-size: 12px
    margin-top: 5px
    position: absolute
</style>

// Imports
import axios from 'axios'

const AxiosConfig = axios.create({
  baseURL: process.env.VUE_APP_BASE_TRACKING_URL + '/api',
  headers: {
    'sso-token': document.querySelector('meta[name="data-user-token"]')?.content
  }
})

export default AxiosConfig

<template>
  <!-- Base Loading Spinner -->
  <div class="base-loading-spinner" />
</template>

<script>
export default {
  name: 'BaseLoadingSpinner'
}
</script>

<style scoped lang="sass">
.base-loading-spinner
  animation: spin 1s linear infinite
  border: 1px white solid
  border-top: 2px black solid
  width: 18px
  height: 18px
  margin: 0 auto
  border-radius: 50%

@keyframes spin
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)
</style>

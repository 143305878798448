// Imports
import AxiosConfig from '@/config/AxiosConfig'

// Process File
export const process = async (filename) => {
  const url = `/erate/process?filename=${filename}`

  try {
    const { data } = await AxiosConfig.get(url)

    if (!data) return []

    return data
  } catch (e) {
    throw new Error(e)
  }
}

export const distributors = async () => {
  const url = '/erate/distributors'

  try {
    const { data } = await AxiosConfig.get(url)

    if (!data) return []

    return data
  } catch (e) {
    throw new Error(e)
  }
}

<template>
  <!-- Archive Account Modal -->
  <base-modal
    @close="cancel"
  >
    <template #toolbar>
      Your Excel File
    </template>

    <template #default>
      <div style="margin: 20px 0; font-size: 14px">
        <vue-file-agent
          ref="vueFileAgent"
          :theme="'list'"
          :multiple="false"
          :deletable="true"
          v-model="fileRecords"
          :meta="true"
          :editable="false"
          :accept="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
          :max-size="'10MB'"
          :max-files="1"
          :help-text="'Choose a file'"
          :error-text="{
            type: 'Invalid file type. Only excel files allowed',
            size: 'Files should not exceed 10MB in size',
          }"
          @select="filesSelected($event)"
          @beforedelete="onBeforeDelete($event)"
          @delete="fileDeleted($event)"
        />
      </div>

      <div style="display: flex; margin-top: 20px; margin-left: auto">
        <base-button
          class="primary--button--text kl-mx-5"
          @click="cancel"
        >
          Cancel
        </base-button>

        <BaseButton
          class="primary--button"
          :loading="loading"
          @click="importData"
        >
          Import
        </BaseButton>
      </div>
    </template>
  </base-modal>
</template>

<script>
import 'vue-file-agent/dist/vue-file-agent.css'
import 'vue-toastification/dist/index.css'

import BaseModal from '@/components/globals/BaseModal'
import BaseButton from '@/components/globals/BaseButton'

export default {
  name: 'UploadModal',
  components: { BaseButton, BaseModal },
  data () {
    return {
      fileRecords: [],
      fileRecordsForUpload: [], // maintain an upload queue
      uploadedFile: null,
      userFile: null,
      loading: false
    }
  },
  computed: {
    uploadUrl () {
      return `${process.env.VUE_APP_BASE_TRACKING_URL}/api/erate/upload`
    },
    headers () {
      return {
        'sso-token': document.querySelector('meta[name="data-user-token"]')?.content
      }
    }
  },
  methods: {
    removeFileRecord (fileRecord) {
      return this.$refs.vfaDemoRef.removeFileRecord(fileRecord)
    },
    uploadFiles: async function () {
      this.loading = true
      // Using the default uploader. You may use another uploader instead.
      const upload = await this.$refs.vueFileAgent.upload(this.uploadUrl, this.headers, this.fileRecordsForUpload)
      try {
        if (upload && upload[0] && upload[0].fileRecord) {
          // this.$toast.success('Logo Uploaded...')
          console.log(upload[0].fileRecord)
          this.uploadedFile = upload[0].fileRecord.upload.data.upload
          this.userFile = upload[0].fileRecord.upload.data.user_filename
        } else {
          this.$toast.success('Upload Error...')
        }
      } catch (e) {
        this.$toast.error('Upload Error...')
        throw new Error(e)
      }
      this.loading = false

      this.fileRecordsForUpload = []
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.headers, fileRecord)
    },
    filesSelected: function (fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
      this.uploadFiles()
    },
    onBeforeDelete: function (fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1)
        var k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) this.fileRecords.splice(k, 1)
      } else {
        if (confirm('Are you sure you want to delete?')) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },
    cancel () {
      this.$emit('close')
    },
    importData () {
      this.$emit('import', { uploadedFile: this.uploadedFile, userFile: this.userFile })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <!-- Product Selector -->
  <div
    id="product-selector"
    class="product-selector"
  >
    <!-- Legacy Data -->
    <div
      v-if="hasLegacyData"
      class="ab-mb-5"
    >
      <div>
        Please provide the specific part number and quantity for the product family you previously selected:
      </div>

      <ul>
        <li
          v-for="(data, index) in legacyData"
          :key="index"
        >
          {{ data.name }}: Quantity {{ data.quantity }}
        </li>
      </ul>

      <div>
        Previous Amount: {{ legacyDataAmount }}
      </div>
    </div>

    <!-- Dropdowns -->
    <div
      v-if="!loadingProducts"
      class="product-selector--select"
    >
      <div class="">
        <h4>
          IMPORTANT: In order to receive Specialty Program discounts (ex. Education or Hospitality), please make designation below.
          Select "None" for base deal registration discounts.
        </h4>

        <div class="ab-flex ab-mb-5">
          <!-- Speciality Programs -->
          <BaseDropdown
            :key="componentKey"
            v-model="selectedSpecialityProgram"
            label="If opportunity applies to a speciality program please make designation below:"
            :items="getSpecialityDiscounts"
            :disabled="!!tableData.length && !isUserAdmin"
            :error="$v.selectedSpecialityProgram.$anyDirty && $v.selectedSpecialityProgram.$invalid"
            required
            @select="handleSelectSpecialityProgram"
          >
            <template #item="{ item }">
              {{ item.title }}
            </template>
          </BaseDropdown>
        </div>
      </div>

      <div
        v-if="showNotERate"
        class="product-selector--dropdowns"
      >
        <div
          class="ab-w-90"
        >
          <!-- Not Qualified Message -->
          <div
            v-if="showNotQualifiedMessage"
            class="not-qualify-message"
          >
            Your company is not qualified for this Specialty Program. Either remove the Specialty Program or contact your Cambium salesperson or
            <a href="mailto:partner@cambiumnetworks.com">partner@cambiumnetworks.com</a> before proceeding
          </div>

          <div
            class="ab-flex ab-mb-5"
          >
            <!-- Product Category -->
            <base-dropdown
              v-model="selectedCategory"
              label="Product Category"
              :items="getCategories"
              required
              :error="$v.productForm.product.$anyDirty && $v.productForm.product.$invalid"
              @select="handleSelectCategory"
            >
              <template #item="{ item }">
                {{ item.title }}
              </template>
            </base-dropdown>

            <!-- Product SKU -->
            <base-dropdown
              v-model="selectedSKU"
              label="Product SKU"
              :items="getSKUs"
              required
              :error="$v.productForm.product.$anyDirty && $v.productForm.product.$invalid"
              class="ab-ml-5"
              @select="handleSelectSKU"
            >
              <template #item="{ item }">
                <div class="dropdown-item">
                  <div>
                    {{ item.title }}
                  </div>

                  <div v-if="item.description">
                    {{ item.description }}
                  </div>
                </div>
              </template>
            </base-dropdown>
          </div>

          <div class="ab-flex">
            <!-- Discount -->
            <BaseInput
              v-if="shouldShowDiscount"
              v-model="productForm.discount"
              label="Discount"
              icon="fas fa-percentage"
              input-type="number"
              disabled
              class="cpq_discount ab-mr-5"
            />

            <!-- MSRP -->
            <BaseInput
              v-if="shouldShowDiscount"
              v-model="productForm.msrp"
              label="MSRP"
              disabled
              icon="fas fa-dollar-sign"
              input-type="number"
              class="ab-w-30 ab-mr-5 cpq_msrp"
            />

            <!-- Quantity -->
            <BaseInput
              v-model="productForm.quantity"
              label="Quantity"
              required
              input-type="number"
              min-value="1"
              class="cpq_quantity"
              :error="$v.productForm.quantity.$anyDirty && $v.productForm.quantity.$invalid"
            />
          </div>
        </div>

        <!-- Add -->
        <div
          class="product-selector--dropdowns--icon ab-bg-green"
          @click="handleAddProduct"
        >
          <i class="fas fa-plus" />
        </div>
      </div>

      <!-- Table -->
      <div
        v-if="showNotERate"
        style="overflow-x: auto; margin-bottom: 25px"
      >
        <BaseTable
          :headers="getTableHeaders"
          :data="tableData"
          class="ab-mt-10 ab-w-full"
        >
          <!-- Discount -->
          <template #discount="{ item }">
            <div v-if="item.discount">
              {{ item.discount }}%
            </div>

            <div v-else>
              -
            </div>
          </template>

          <!-- MSRP -->
          <template #msrp="{ item }">
            {{ item.msrp | price }}
          </template>

          <template #quantity="{ item }">
            <!-- Quantity -->
            <BaseInput
              v-model="item.quantity"
              input-type="number"
              min-value="1"
              :error="$v.productForm.quantity.$anyDirty && $v.productForm.quantity.$invalid"
              class="cpq_item_quantity"
            />
          </template>

          <!-- Total -->
          <template #total="{ item }">
            {{ getProductTotal(item) | price }}
          </template>

          <!-- Remove -->
          <template #actions="{ item }">
            <div
              class="product-selector--dropdowns--icon ab-bg-red"
              @click="handleRemoveProduct(item)"
            >
              <i class="fas fa-minus" />
            </div>
          </template>
        </BaseTable>
      </div>
      <erate-app
        :key="keyRate"
        v-if="showERate"
        :owner-id="getOwnerId"
        :uploaded-file="getUploadedFile"
        :excel="getExcelData"
        :products="getCategories"
        :configurator="getConfigurator"
        :title="getERateTitle"
        :status="getStatus"
        :distributors-field="getFormFields.erate_distributor"
        :uat-field="getFormFields.erate_discount_uat"
        @uploadSuccess="handleUploadSuccess"
        @excelProcessed="handleExcelProcessed"
        @configuratorSelected="handleConfiguratorSelected"
        @selectorEvent="handleSelectorEvent"
        @enableSkip="handleSkipEvent"
        @resetBom="handleResetBOM"
      />
    </div>

    <div style="margin-bottom: 40px;" v-if="(skipConfigurator === 'Yes' && ['submitted', 'pre-approved'].includes(getStatus))">
      <base-button
        style="margin-top: 20px; cursor: pointer;"
        class="primary--button ab-border-gray ab-text-primary bg-white"
        @click="resetSkipConfigurator"
      >
        Upload an E-Rate 2022 BOM Configurator
      </base-button>
    </div>

    <!-- Contact distributor message -->
    <div
      v-if="!shouldShowDiscount"
      class="not-qualify-message ab-mt-5"
    >
      Please contact your distributor for pricing.
    </div>

    <!-- E-Rate required products message -->
    <div
      v-if="shouldShowERateRequiredMessage"
      class="not-qualify-message ab-mt-5"
    >
      For E-rate deal registrations, please refer to the
      <a
        href="https://channel.cambiumnetworks.com/engage/cambium-e-rate-2021-partner-resource-kit/?play=85854"
        target="_blank"
      >
        E-rate Resource Kit
      </a> for specific instructions
      <!-- , and important XMS-Cloud bundling requirements. Failure to follow bundling requirements will result in your deal request being delayed or denied. -->
    </div>
  </div>
</template>

<script>
import BaseDropdown from './components/globals/BaseDropdown'
import BaseTable from './components/globals/BaseTable'
import BaseInput from './components/globals/BaseInput'
import ErateApp from '@/components/ErateApp'

import generateRandomID from './utils/generateRandomID'
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import Axios from 'axios'
import bowser from 'bowser'

export default {
  name: 'App',
  components: { BaseInput, BaseTable, BaseDropdown, ErateApp },
  filters: {
    // Price Filter
    price (val) {
      return '$' + Number(val).toFixed(2)
    }
  },
  mixins: [validationMixin],
  validations: {
    productForm: {
      product: {
        required
      },
      quantity: {
        required,
        minValue: minValue(1)
      }
    },
    selectedSpecialityProgram: {
      required
    }
  },
  props: {
    // Form Fields
    abFormFields: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      legacyDataAmount: 0,
      componentKey: 0,
      loadingProducts: false,
      formFields: {},
      selectedSKU: {},
      selectedCategory: {
        products: []
      },
      selectedSpecialityProgram: {},
      tableHeaders: [],
      tableData: [],
      productForm: {},
      products: [],
      loadingDiscount: false,
      specialityDiscounts: [
        { id: 0, value: 'None', title: 'None' },
        { id: 1, value: 'Education', title: 'Education' },
        { id: 2, value: 'Hospitality', title: 'Hospitality' },
        { id: 3, value: 'E-Rate', title: 'E-Rate' },
        { id: 5, value: 'E-Rate 2022', title: 'E-Rate 2022' },
        { id: 4, value: 'Healthcare', title: 'Healthcare' },
        { id: 6, value: 'Carrier Agent', title: 'Carrier Agent' }

      ],
      showNotQualifiedMessage: false,
      hasLegacyData: false,
      legacyData: [],
      currentUser: {
        user_groups: []
      },
      skipConfigurator: false,
      resetEbom: false,
      keyRate: 1,
      newExcel: [],
      newUploadedFile: null
    }
  },
  computed: {
    showNotERate () {
      return this.selectedSpecialityProgram && this.selectedSpecialityProgram.value && (this.selectedSpecialityProgram.value !== 'E-Rate 2022' || this.skipConfigurator === 'Yes')
    },
    showERate () {
      return this.selectedSpecialityProgram && this.selectedSpecialityProgram.value && (this.selectedSpecialityProgram.value === 'E-Rate 2022' && this.skipConfigurator !== 'Yes')
    },
    getTableHeaders () {
      const headers = [
        { id: 1, title: 'Category', value: 'category', style: 'min-width: 170px' },
        { id: 2, title: 'SKU', value: 'sku', style: 'min-width: 170px' },
        { id: 5, title: 'Qty', value: 'quantity', style: 'min-width: 80px' },
        { id: 7, title: null, value: 'actions', class: 'ml-auto' }
      ]

      if (this.shouldShowDiscount) {
        headers.splice(3, 0, { id: 4, title: 'Discount', value: 'discount' })
        headers.splice(2, 0, { id: 3, title: 'MSRP', value: 'msrp' })
        headers.splice(5, 0, { id: 6, title: 'Total', value: 'total', class: 'text-right' })
      }

      return headers
    },
    // Get Categories
    getCategories () {
      if (!this.products.length) return []

      const categories = JSON.parse(this.products)

      return Object.keys(categories).map((category, index) => {
        const products = []
        const currentCategory = Object.keys(categories[Object.keys(categories)[index]])
        // Loop through categories children
        for (let j = 0; j < currentCategory.length; j++) {
          const currentSKU = currentCategory[j]

          // Push to Product SKU's List
          products.push({
            sku: currentSKU,
            ...categories[Object.keys(categories)[index]][currentSKU]
          })
        }

        return {
          id: index,
          title: category,
          products
        }
      }).sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)
    },
    // Get Speciality Discounts
    getSpecialityDiscounts () {
      const jsonOutputField = document.querySelector(this.getFormFields.json_output)
      let data = []

      if (jsonOutputField?.value) {
        data = JSON.parse(jsonOutputField.value)
      }

      if (data.program === 'E-Rate') {
        return this.specialityDiscounts
      }

      return [...this.specialityDiscounts].filter((sd) => {
        return sd.id !== 3
      })
    },
    // Get SKUs
    getSKUs () {
      return this.selectedCategory.products?.map((sku, index) => {
        return {
          id: index,
          title: sku.sku,
          sku: sku.sku,
          price: sku.basePrice || 0,
          description: sku.description,
          productCategory: sku.class
        }
      }).sort((a, b) => a.title > b.title ? 1 : -1)
    },
    // Get Form Fields
    getFormFields () {
      if (!this.abFormFields) return {}
      return JSON.parse(this.abFormFields)
    },
    // Erate Uploaded File
    getUploadedFile () {
      if (this.keyRate > 1) {
        return null
      }
      const uploadedFile = document.querySelector(this.getFormFields.uploaded_file)

      return uploadedFile ? uploadedFile.value : null
    },
    getExcelData () {
      if (this.keyRate > 1) {
        return []
      }
      const excelData = document.querySelector(this.getFormFields.excel_data)
      return excelData && excelData.value ? JSON.parse(excelData.value) : []
    },
    getStatus () {
      const status = document.querySelector(this.getFormFields.status)
      return status ? status.value : ''
    },
    getERateTitle () {
      if (this.getExcelData.length) {
        return 'E-RATE'
      } else {
        return 'STEPS TO SET UP YOUR E-RATE 2022 DEAL REGISTRATION'
      }
    },
    getConfigurator () {
      if (this.keyRate > 1) {
        return null
      }
      const configurator = document.querySelector(this.getFormFields.configurator)

      return configurator && configurator.value ? JSON.parse(configurator.value) : null
    },
    // Get Owner ID
    getOwnerId () {
      const browserField = document.querySelector(this.getFormFields.owner_id)

      return browserField ? browserField.value : ''
    },
    // Only show discount when company id array doesn't have the disable discount group
    shouldShowDiscount () {
      if (!this.getFormFields.disable_discount_group) {
        return
      }

      return this.currentUser.user_groups.map(group => group.group_id).every(v => !this.getFormFields.disable_discount_group.includes(v)) || this.isUserAdmin
    },
    // Should show E-Rate required message
    shouldShowERateRequiredMessage () {
      return this.selectedSpecialityProgram.value === 'E-Rate' || this.selectedSpecialityProgram.value === 'E-Rate 2022'
    },
    isUserAdmin () {
      return window.abUser?.systemrole === 'administrator'
    }
  },
  watch: {
    // Watch table data and emit events
    tableData: {
      handler (newValue) {
        if (newValue) {
          this.handleTableProcessing(newValue)
        }
      },
      deep: true
    },
    getOwnerId (newValue) {
      this.getUser(newValue)
    }
  },
  async mounted () {
    const skipConfigurator = document.querySelector(this.getFormFields.skip_configurator)
    this.skipConfigurator = skipConfigurator ? skipConfigurator.value : false
    await this.getUser()
    const products = await Axios.get(process.env.VUE_APP_BASE_API_URL + '/products.json')

    this.products = products.data
    const browserField = document.querySelector(this.getFormFields.browser_output)

    if (browserField) {
      const result = bowser.getParser(window.navigator.userAgent)
      browserField.value = `You are using ${result.parsedResult.browser.name} - version: ${result.parsedResult.browser.version} on a ${result.parsedResult.os.name}`
    }

    // Amount Field
    const amountField = document.querySelector(this.getFormFields.amount)
    if (this.shouldShowDiscount) {
      //
    } else {
      if (amountField) {
        amountField.parentElement.parentElement.style.display = 'none'
      }
    }

    if (amountField) {
      amountField.readOnly = true
    }

    // Populate Data if there's data
    const jsonOutputField = document.querySelector(this.getFormFields.json_output)
    // Legacy Data
    const legacyDataField = document.querySelector(this.getFormFields.legacy_data)

    let data = []

    if (legacyDataField?.value) {
      data = JSON.parse(legacyDataField.value)
      this.legacyDataAmount = amountField.value

      if (data && data.length) {
        this.hasLegacyData = true
        this.legacyData = data
      }

      amountField.value = ''
    }

    if (jsonOutputField?.value) {
      data = JSON.parse(jsonOutputField.value)
      this.tableData = data.data
    }

    const foundSpeciality = this.specialityDiscounts.find(d => d.value === data.program)

    if (foundSpeciality) {
      this.selectedSpecialityProgram = foundSpeciality
      this.componentKey++
    }

    this.handleTrackingId()
  },
  methods: {
    resetSkipConfigurator () {
      this.handleResetBOM()
      const skipConfigurator = document.querySelector(this.getFormFields.skip_configurator)
      if (skipConfigurator) {
        skipConfigurator.value = ''
      }
      this.skipConfigurator = ''
    },
    // Get Product Total
    getProductTotal (product) {
      const productDiscount = ((product.discount * product.msrp) / 100)
      const productPrice = product.msrp

      const totalProduct = productPrice - productDiscount

      return (totalProduct * product.quantity).toFixed(2)
    },
    handleSkipEvent () {
      this.skipConfigurator = 'Yes'
      const skipConfigurator = document.querySelector(this.getFormFields.skip_configurator)
      if (skipConfigurator) {
        skipConfigurator.value = this.skipConfigurator
      }
    },
    handleResetBOM () {
      document.querySelector(this.getFormFields.excel_data).value = []
      document.querySelector(this.getFormFields.json_output).value = ''
      document.querySelector(this.getFormFields.html_output).value = ''
      document.querySelector(this.getFormFields.configurator).value = ''
      document.querySelector(this.getFormFields.amount).value = ''
      this.tableData = []
      this.keyRate = this.keyRate + 1
    },
    // Handle Select Category
    handleSelectCategory (category) {
      this.productForm = {}
      this.selectedCategory = category
      this.$set(this.productForm, 'category', category.title)
      this.selectedSKU = {}
    },
    // Get User
    async getUser () {
      const baseURL = process.env.VUE_APP_BASE_API_URL
      const url = `${baseURL}/?get_user=${this.getOwnerId}`

      const { data } = await Axios.get(url)

      this.currentUser = data
    },
    // Handle Select Program
    handleSelectSpecialityProgram (program) {
      console.log(this.selectedSpecialityProgram.value)
      console.log(this.selectedSpecialityProgram)
      this.showNotQualifiedMessage = false

      const groupID = this.currentUser.user_groups.map(group => group.group_id) || []

      const programs = {
        Education: ['389'],
        Hospitality: ['390'],
        'E-Rate': ['8605', '8607', '9625'],
        'E-Rate 2022': ['8605', '8607', '9625'],
        Healthcare: ['8609', '10262'],
        MSRP1: ['8610', '10658'],
        MSRP2: ['8611', '10659'],
        'Carrier Agent': ['8621', '12345']
      }

      const specialityProgramField = document.querySelector(this.getFormFields.speciality_program)

      if (specialityProgramField) {
        if (program.value !== 'None') {
          if (groupID.some(id => programs[program.value]?.includes(id)) || this.isUserAdmin) {
            specialityProgramField.value = program.title
            this.selectedSpecialityProgram = program
            this.showNotQualifiedMessage = false
          } else {
            this.showNotQualifiedMessage = true
            this.selectedSpecialityProgram = { id: 0, value: 'None', title: 'None' }
            specialityProgramField.value = 'None'
          }
        } else {
          specialityProgramField.value = 'None'
          this.selectedSpecialityProgram = { id: 0, value: 'None', title: 'None' }
        }
      }

      this.componentKey++

      if (this.isUserAdmin) {
        this.recalculateDiscounts()
      }
    },
    async recalculateDiscounts () {
      const result = []
      const tableData = [...this.tableData]

      for (let i = 0; i < tableData.length; i++) {
        const newItem = { ...tableData[i] }
        const discount = await this.getDiscount(tableData[i].sku)

        newItem.discount = discount.discount || 0
        newItem.distributorRebate = discount.distributor_rebate
        newItem.discountDetails = discount.discount_details

        result.push(newItem)
      }

      this.tableData = result
    },
    // Handle Select SKU
    async handleSelectSKU (sku) {
      this.selectedSKU = sku.sku

      const getDiscount = await this.getDiscount(sku.sku)
      this.productForm.discount = getDiscount.discount || 0
      this.productForm.distributorRebate = getDiscount.distributor_rebate
      this.productForm.discountDetails = getDiscount.discount_details

      if (this.getFormFields.uat_output) {
        const discountUATField = document.querySelector(this.getFormFields.uat_output)

        if (discountUATField) {
          discountUATField.value = JSON.stringify(getDiscount)
        }
      }

      this.$set(this.productForm, 'product', sku.title)
      this.productForm.sku = sku.sku
      this.productForm.msrp = sku.price || 0
      this.productForm.description = sku.description
      this.productForm.productCategory = sku.productCategory
      this.productForm.quantity = 1
    },
    // Handle Add Product
    handleAddProduct () {
      if (this.$v.selectedSpecialityProgram.$invalid) {
        this.$v.$touch()

        return
      }

      // New Product
      const newProduct = {
        uuid: generateRandomID(),
        product: this.productForm.product,
        category: this.productForm.category,
        sku: this.productForm.sku,
        discount: this.productForm.discount || 0,
        msrp: this.productForm.msrp || 0,
        quantity: this.productForm.quantity || 1,
        description: this.productForm.description,
        distributorRebate: this.productForm.distributorRebate || '',
        discountDetails: this.productForm.discountDetails || '',
        productCategory: this.productForm.productCategory.class
      }

      // Add product to table
      this.tableData.push(newProduct)

      // Reset Form
      this.productForm = {}
      this.selectedCategory = {}
      this.selectedSKU = {}
      this.$v.$reset()
    },
    // Handle Remove Product
    handleRemoveProduct (item) {
      this.tableData = [...this.tableData.filter(tableItem => tableItem.uuid !== item.uuid)]
    },
    // Handle Table Processing
    handleTableProcessing (data) {
      // HTML Output
      const htmlOutput = []

      // Generate HTML Output
      for (let i = 0; i < data.length; i++) {
        const discountPrice = ((data[i].discount * data[i].msrp) / 100)
        const productPrice = data[i].msrp

        const totalPrice = productPrice - discountPrice

        htmlOutput.push(`
          <p>
            ${data[i].product} -
            $${totalPrice.toFixed(2)} x ${data[i].quantity} =
            $${(totalPrice * (data[i].quantity)).toFixed(2)}
          </p>
        `)
      }

      // Table Total
      const tableTotal = data.reduce((a, b) => {
        const discountPrice = (b.discount * b.msrp / 100)
        const productPrice = b.msrp

        return a + ((productPrice - discountPrice) * b.quantity)
      }, 0)

      // Amount Field
      const amountField = document.querySelector(this.getFormFields.amount)
      // JSON Output Field
      const jsonOutputField = document.querySelector(this.getFormFields.json_output)
      // HTML Output Field
      const htmlOutputField = document.querySelector(this.getFormFields.html_output)

      // Hydrate Fields
      if (amountField) amountField.value = tableTotal.toFixed(2)
      if (this.shouldShowERateRequiredMessage) {
        const node = document.createElement('div')
        node.className = 'gfield_description erateOnly'
        node.innerHTML = 'Deal Amount only includes Deal Reg Eligible items'
        node.style.marginBottom = '6px'
        amountField.insertAdjacentElement('beforebegin', node)
      } else {
        const erateDiv = document.querySelector('.erateOnly')
        if (erateDiv) {
          erateDiv.remove()
        }
      }
      if (jsonOutputField) jsonOutputField.value = JSON.stringify({ data, program: this.selectedSpecialityProgram.value })
      if (htmlOutputField) htmlOutputField.value = htmlOutput.join('')
    },
    // Get Discount
    async getDiscount (product) {
      const baseURL = process.env.VUE_APP_BASE_API_URL
      let url = `${baseURL}/?get_discounts=1&user_id=${this.getOwnerId}&sku=${product}`

      if (this.selectedSpecialityProgram.value) {
        url += `&speciality_program=${!this.showNotQualifiedMessage ? this.selectedSpecialityProgram.value : 'None'}`
      }

      this.loadingDiscount = true
      try {
        const discounts = await Axios.get(url)

        return discounts.data || {}
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingDiscount = false
      }
    },
    handleTrackingId () {
      const trackingBaseURL = process.env.VUE_APP_BASE_TRACKING_URL
      const transactionId = document.querySelector(this.getFormFields.transaction_id)
      if (!transactionId.value) {
        const url = `${trackingBaseURL}/api/tracking`
        Axios.post(url, {
          generate_tracking_id: 2
        }).then(response => {
          transactionId.value = `TRK-${response.data.tracking_id}`
        }).catch(e => {
          console.log(e)
        })
      }
    },
    handleUploadSuccess (file) {
      const fileField = document.querySelector(this.getFormFields.uploaded_file)
      if (fileField) {
        fileField.value = file
      } else {
        console.log('File Field not defined')
      }
    },
    handleExcelProcessed (excel) {
      const fileField = document.querySelector(this.getFormFields.excel_data)
      if (fileField) {
        fileField.value = JSON.stringify(excel)
        console.log(fileField.value)
      } else {
        console.log('File Field not defined')
      }
    },
    handleConfiguratorSelected (configurator) {
      const fileField = document.querySelector(this.getFormFields.configurator)
      if (fileField) {
        fileField.value = JSON.stringify(configurator)
        console.log(fileField.value)
      } else {
        console.log('File Field not defined')
      }
    },
    handleSelectorEvent (object) {
      const amountField = document.querySelector(this.getFormFields.amount)
      // JSON Output Field
      const jsonOutputField = document.querySelector(this.getFormFields.json_output)
      // HTML Output Field
      const htmlOutputField = document.querySelector(this.getFormFields.html_output)

      // Hydrate Fields
      if (amountField) amountField.value = object.total.toFixed(2)
      if (jsonOutputField) jsonOutputField.value = JSON.stringify({ data: object.tableData, program: this.selectedSpecialityProgram.value })
      if (htmlOutputField) htmlOutputField.value = object.htmlOutput.join('')
    }
  }
}
</script>

<style scoped lang="sass">
.product-selector
  font-family: Roboto, sans-serif
  margin: 0 auto
  width: 100%

  &--select
    align-items: center
    display: block
    flex-direction: row

  &--dropdowns
    align-items: center
    display: flex
    flex-direction: row

    &--icon
      align-items: center
      border-radius: 50%
      color: white
      cursor: pointer
      display: flex
      font-size: 14px
      height: 25px !important
      justify-content: center
      margin: 0 auto
      min-width: 25px !important
      max-width: 25px !important

      i
        transform: translateY(0.5px)

.not-qualify-message
  color: red
  background-color: #D2D2D2
  border-radius: 4px
  font-size: 14px
  padding: 10px 15px
  margin: -15px 0 20px 0

.dropdown-item
  div:first-child
    font-weight: bold

  div:nth-child(2)
    text-overflow: ellipsis
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    margin: 5px 0
    font-size: 12px

@media only screen and (max-width: 600px)
  .product-selector--dropdowns--icon
    margin: 0 40px
</style>
